export const useSideBar = () => {

    const opened = localStorage.getItem('sideBarOpened') === 'false' ? false : true

    const sideBar = ref({
        opened: opened
    })    

    const toggleSideBar = () => {
        sideBar.value.opened = !sideBar.value.opened
        localStorage.setItem('sideBarOpened', sideBar.value.opened.toString())
    }    

    return { sideBar, toggleSideBar }

}