<template>
    <div class="bg-white dark:bg-gray-900">
        <div v-if="loading" class="absolute top-0 w-full">
            <UProgress animation="carousel" size="xs" />
        </div>
        <div class="flex h-screen overflow-hidden ">
            <div v-if="sideBar.opened" class="w-56 pt-1 pe-1 ">
                <SidebarLeft @closeSidebar="toggleSideBar" />
            </div>
            <div class="flex flex-1 flex-col w-full">
                <div v-if="user?.verificationCode" class="p-2 bg-amber-400 text-black">
                    <p class="text-sm flex items-center gap-1">
                        <UIcon name="i-heroicons-exclamation-circle" class="w-6 h-6"/>
                        Seu e-mail {{ user.email }} não foi verificado. Verifique sua caixa
                        de entrada ou <UButton @click="sendVerificationEmail" variant="link" :padded="false" color="white">
                            clique aqui para reenviar o link de verificação</UButton>.
                    </p>
                </div>                
                <div class="relative ">
                    <div class="flex justify-between p-1 ">
                        <div class="flex items-center">
                            <UTooltip v-if="!sideBar.opened" text="Abrir menu">
                                <UButton icon="i-heroicons-bars-3-20-solid" color="gray" variant="ghost"
                                    class="rounded-none" @click="toggleSideBar"></UButton>
                            </UTooltip>
                            <div class="ms-1">
                                <Breadcrumbs />
                            </div>
                        </div>
                        <div>
                            <UTooltip text="Alternar modo escuro/claro">
                                <UButton :icon="!isDark ? 'i-heroicons-moon-20-solid' : 'i-heroicons-sun-20-solid'"
                                    variant="ghost" @click="isDark = !isDark" />
                            </UTooltip>
                            <UTooltip text="Novidades">
                                <UButton id="beamerBt" icon="i-heroicons-newspaper" variant="ghost" />
                            </UTooltip>
                            <UTooltip text="Configurações">
                                <UButton to="/settings" icon="i-heroicons-cog-6-tooth" variant="ghost" />
                            </UTooltip>
                        </div>
                    </div>
                </div>
                <div class="flex flex-grow-0 flex-col h-screen overflow-y-auto rounded-tl-xl bg-gray-100 dark:bg-gray-950 ">
                    <div class="p-6 pb-24">
                        <slot />
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>

import { storeToRefs } from 'pinia' // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/store/auth' // import the auth store we just created

const { sideBar, toggleSideBar } = useSideBar()

const { user } = storeToRefs(useAuthStore()) // make authenticated state reactive with storeToRefs
const toast = useToast()

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    }
})

const colorMode = useColorMode();
const isDark = computed({
    get() {
        return colorMode.value === 'dark'
    },
    set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
    }
})

async function sendVerificationEmail() {
    await useFetch('/api/user/verifyemailsend', { method: 'POST' }).then(() => toast.add({
        title: 'Link enviado',
        description: 'Procure o link de verificação na sua caixa de entrada.',
        icon: "i-heroicons-envelope-20-solid",
        color: "green"
    })
    )
}

toggleJivoChatVisibility(true)
</script>