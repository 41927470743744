const modules = [
    {
        label: "Vagas",
        name: "Vagas",
        description: "Gerenciar vagas e candidatos",
        to: "/job/opened",
        color: "orange",
        icon: "i-heroicons-briefcase-20-solid"
    },
    // {
    //     label: "Comunicados",
    //     name: "Comunicados",
    //     description: "Mantenha todos os colaboradores informados",
    //     to: "/onboarding",
    //     color: "cyan",
    //     icon: "i-heroicons-speaker-wave-20-solid"
    // },
    // {
    //     label: "Onboarding",
    //     name: "Onboarding",
    //     description: "Gerenciar o processo de onboarging dos novos colaboradores",
    //     to: "/onboarding",
    //     color: "purple",
    //     icon: "i-heroicons-user-plus-20-solid"
    // },
    // {
    //     label: "Pesquisas",
    //     name: "Pesquisas",
    //     description: "Gereciar pesquisas de clima e engajamento",
    //     to: "/pesquisas",
    //     color: "red",
    //     icon: "i-heroicons-document-chart-bar-20-solid"
    // },
    // {
    //     label: "Gestão de Pessoas",
    //     name: "Gestão de Pessoas",
    //     description: "Gerenciar os colaboradores da empresa",
    //     to: "/gestao_pessoas",
    //     color: "blue",
    //     icon: "i-heroicons-users-20-solid"
    // },
    {
        label: "Empresa",
        name: "Empresa",
        description: "Atualizar os dados da minha empresa",
        to: "/company",
        color: "green",
        icon: "i-heroicons-building-office-20-solid"
    }
    // ,
    // {
    //     label: "Biblioteca",
    //     name: "Biblioteca",
    //     description: "Acesse a biblioteca com os melhores conteúdos",
    //     to: "/biblioteca",
    //     color: "yellow",
    //     icon: "i-heroicons-book-open-20-solid"
    // }

];

export const useModules = () => {
    return modules;
}